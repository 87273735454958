.yearpass_container {
    margin: 10rem 0;
    .about-yerapass {
        margin: 0 0 10rem;
    }
    .four-step {
        margin: 0 0 12rem;
        text-align: center;
        .c-title24 {
            margin: 0 0 4.8rem;
        }
        &_wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .step-item {
                width: 23.4%;
                @include ts {
                    width: 48%;
                    margin: 0 0 3%;
                }
                @include sp {
                    width: 100%;
                    margin: 0 0 2rem;
                }
                background-color: #fff;
                border-radius: 12px;
                &_wrap {
                    padding: 24px 20px;
                    .step {
                        border-radius: 20px;
                        padding: 4px 0;
                        background-color: $orange;
                        color: #fff;
                        margin: 0 0 24px;
                    }
                    .title {
                        color: $orange;
                        margin: 0 0 1.6rem;
                    }
                    .text {
                        font-size: 14px;
                        a {
                            color: $orange;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    &__form {
        &__desc{
            margin-bottom: 5rem;
        }
        .flexbetweens {
            dl {
                width: 48.9%;
            }
        }
        .note {
            text-align: left;
            margin: 0 0 2.4rem;
        }
        .address {
            .addnum {
                display: flex;
                align-items: center;
                margin: 0 0 1.8rem;
                span {
                    padding: 0 1.6rem 0 0;
                }
                .c-formInput {
                    margin: 0;
                    width: 24rem;
                }
            }
        }
        .belongs {
            width: 48.9%;
        }
        .payment {
            margin: 8rem 0 0;
        }
        .remark {
            margin: 8rem 0 0;
        }
        .c-formCheckbox--horizontal {
            justify-content: center;
            align-items: center;
        }
        h3{
            margin-bottom: 3.2rem;
            font-size: 2.4rem;
        }
        p{
            font-size: 1.4rem;
            text-align: center;
        }
        dl{
            margin-bottom: 2rem;
        }
        dt,dd{
            font-size: 1.4rem;
        }
        dt{
            margin-bottom: 1rem;
            color: $orange;
        }
        &__btn{
            margin-top: 4rem;
            text-align: center;
        }
        &__link{
            margin-top: 2rem;
            text-align: center;
            a{
                color: $orange;
                font-size: 1.6rem;
            }
        }
        .c-formSelect {
            .select {
                max-width: 100%;
            }
        }
        .p-login__btn {
            display: flex;
            justify-content: center;
            div {
                &:first-child {
                    margin: 0 1.6rem 0 0;
                }
            }
        }
    }
}