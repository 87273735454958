// <div class="c-orangeBtn"><a href=""><span class="c-orangeBtn__txt">ボタンテキスト</span></a></div>
.c-orangeBtn{
    display: inline-block;
    width: 100%;
    max-width: 32rem;
    a,button,input[type=submit]{
        padding: 1.4rem 0;
        display: block;
        width: 100%;
        background-color: $orange;
        border-radius: .8rem;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: center;
        position: relative;
    }
    &__txt{
        vertical-align: middle;
    }
}
// <div class="c-whiteBtn"><a href=""><span class="c-whiteBtn__txt">ボタンテキスト</span></a></div>
.c-whiteBtn{
    display: inline-block;
    width: 100%;
    max-width: 32rem;
    a,button,input[type=submit]{
        padding: 1.2rem 0 1.5rem;
        display: block;
        width: 100%;
        background-color: #fff;
        border: solid $orange;
        border-width: .1rem .1rem .3rem .1rem;
        border-radius: .8rem;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: center;
        position: relative;
        &:hover{
            opacity: 1;
            transition: .1s;
            transform: translateY(.2rem);
            border-width: .1rem .1rem .1rem .1rem
        }
    }
    &__txt{
        vertical-align: middle;
    }
    // <div class="c-btn--whiteBtn -small"><a href=""><span class="c-btn--whiteBtn__txt">ボタンテキスト</span></a></div>
    &.-small{
        max-width: auto;
        max-width: initial;
        max-width: none;
        width: auto;
        a{
            padding: .3rem 1.1rem;
            font-size: 1.4rem;
            border-width: .1rem .1rem .2rem .1rem;
            border-radius: .4rem;
            &:hover{
                transform: translateY(.1rem);
                border-width: .1rem .1rem .1rem .1rem
            }
        }
    }
    // <div class="c-whiteBtn -icon_env"><a href=""><span class="c-whiteBtn__txt">ボタンテキスト</span></a></div>
    &.-icon_env{
        a{
            position: relative;
            &::before{
                margin-right: .8rem;
                content: '';
                display: inline-block;
                width: 2.4rem;
                height: 1.92rem;
                background: no-repeat url(../img/icon_envelope.png) center center / contain;
                vertical-align: middle;
            }
        }
    }
    // <div class="c-whiteBtn -arrowed"><a href=""><span class="c-whiteBtn__txt">ボタンテキスト</span></a></div>
    &.-arrowed{
        a{
            &::after{
                margin-top: -.2rem;
                margin-left: .4rem;
                content: '';
                display: inline-block;
                width: .7rem;
                height: .7rem;
                border: solid $brown;
                border-width: 2px 2px 0 0;
                vertical-align: middle;
                transform-origin: center center;
                transform: rotate(45deg);
            }
        }
    }
    &.-contact{
        margin-right: 3rem;
        @include ts{
            margin-right: 0;
            margin-bottom: 2rem;
        }
        a,button,input{
            border-width: .1rem .1rem .1rem .1rem;
            color: $orange;
        }
    }
}

.c-transparentBtn{
    display: inline-block;
    width: 100%;
    max-width: 32rem;
    a,button,input[type=submit]{
        padding: 1.4rem 0;
        display: block;
        width: 100%;
        background-color: transparent;
        border: 1px solid $orange;
        border-radius: .8rem;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: center;
        position: relative;
    }
    &__txt{
        vertical-align: middle;
        color: $orange;
    }
}