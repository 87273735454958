.p-drawer{
    ul{
        li{
            border: dashed $orange;
            border-width: 0 0 1px 0;
            line-height: 2rem;
            @include ts{
            }
            a{
                padding: 1.5rem;
                display: block;
                font-size: 1.6rem;
                position: relative;
                .icon{
                    // position: absolute;
                    // top: 50%;
                    // left: 1.6rem;
                    margin-right: 1rem;
                    width: 1.6rem;
                    height: 1.6rem;
                    display: inline-block;
                    img{
                        height: 100%;
                        width: auto;
                    }
                }
                .txt{
                    display: inline-block;
                }
            }
            &:first-child{
                border-top: none;
            }
        }
    }
    &__btn{
        padding: 20px 15px;
    }
}