/*
ブロックA（画像とテキストが横並び）

Markup:
<div class="p-blockA">
    <div class="p-blockA__img">
        <img src="<%- dir %>assets/img/home/63610048.png" alt="">
    </div>
    <div class="p-blockA__txt">
        <h3 class="c-title24">一人ひとりに向き合い<br>理解するためのツールを提供しています</h3>
        <p>自閉症eサービスがこれまで支援現場で培ってきた、現場で有用に活用できる「評価キット」と「レジュメ集」を提供しています。</p>
        <div class="btn c-whiteBtn"><a href=""><span class="c-whiteBtn__txt">評価キット＆レジュメ集を見る</span></a></div>
    </div>
</div>
*/
.p-blockA{
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include ts{
        display: block;
    }
    &.-reverse{
        flex-direction: row-reverse;
    }
    &__img{
        width: 49%;
        @include ts{
            width: 100%;
        }
        .-reverse &{
            width: 54.545454%;
            @include ts{
                width: 100%;
            }
        }
    }
    &__txt{
        width: 45.8%;
        padding-top: 1.8rem;
        padding-right: 5%;
        @include ts{
            width: 100%;
            padding-right: 0;
        }
        .title{
            margin-bottom: 1.2rem;
        }
        .btn{
            margin-top: 2.9rem;
        }
        .-reverse &{
            width: 45.454545%;
            @include ts{
                width: 100%;
            }
        }
    }
}

/*
ブロックB

Markup:
<div class="p-blockB">
    <div class="p-blockB__img">
        <img src="<%- dir %>assets/img/home/54961267.png" alt="">
    </div>
    <div class="p-blockB__txt">
        <p>ご家族向けに個別相談や学習会や、ご本人様向けに一人ひとりに合わせた旅行企画などを実施しております。</p>
        <div class="btn c-whiteBtn"><a href=""><span class="c-whiteBtn__txt">ご本人・ご家族向けサービスを見る</span></a></div>
    </div>
</div>
*/
.p-blockB{
    &__img{
        margin-bottom: 1.9rem;
    }
    &__txt{
        padding-right: 4rem;
        @include ts{
            padding-right: 0;
        }
    }
    h3{
        text-align: center;
    }
    p{
        line-height: 1.8;
    }
    .btn{
        margin: 3.4rem auto 0;
        display: block;
    }
}

/*
ブロックC（全部中央寄せ）

Markup:
<div class="p-blockC">
    <h2 class="c-title24">自閉症eサービスを通して、<br>「自分らしく」活動できる未来を。</h2>
    <p>ご家族向けに個別相談や学習会や、ご本人様向けに一人ひとりに合わせた旅行企画などを実施しております。</p>
    <div class="btn c-whiteBtn"><a href=""><span class="c-whiteBtn__txt">ご本人・ご家族向けサービスを見る</span></a></div>
</div>
*/
.p-blockC{
    text-align: center;
    p{
        margin-bottom: 3.5rem;
    }
}