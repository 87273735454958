// ・一般的なPCサイズ(1440px、1366px、1280px)
// ・タブレットサイズの縦横(1024px、768px)
// ・スマホ(480px〜375pxまで可能な限り)

@mixin pl{
    // @media screen and (max-width:90em) {
    //     @content;
    // }
    @media screen and (max-width:1440px) {
        @content;
    }
}

@mixin pm{
    // @media screen and (max-width:85.375em) {
    //     @content;
    // }
    @media screen and (max-width:1366px) {
        @content;
    }
}
@mixin ps{
    // @media screen and (max-width:80em) {
    //     @content;
    // }
    @media screen and (max-width:1280px) {
        @content;
    }
}

// タブレット大
@mixin tl{
    // @media screen and (max-width:64em) {
    //     @content;
    // }
    @media screen and (max-width:1024px) {
        @content;
    }
}
// スマホ横
@mixin sl{
    // @media screen and (max-width:56em) {
    //     @content;
    // }
    @media screen and (max-width:896px) {
        @content;
    }
}
// タブレット小
@mixin ts{
    // @media screen and (max-width:48em) {
    //     @content;
    // }
    @media screen and (max-width:768px) {
        @content;
    }
}

// スマホ
@mixin sp{
    // @media screen and (max-width:30em) {
    //     @content;
    // }
    @media screen and (max-width:480px) {
        @content;
    }
}

// スマホ小
@mixin ss{
    // @media screen and (max-width:em) {
    //     @content;
    // }
    @media screen and (max-width:360px) {
        @content;
    }
}