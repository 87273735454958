.event-container {
    .article {
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width:1023px) {
                justify-content: space-between;
            }
            &_item {
                width: 31.59%;
                margin: 0 0 2.4rem 2.4rem;
                @media screen and (max-width:1023px) {
                    width: 48%;
                }
                @include sp {
                    margin: 0 0 2.4rem 0;
                    width: 100%;
                }
                &:nth-child(3n-2) {
                    margin: 0 0 0;
                    @media screen and (max-width:1023px) {
                        margin: 0 0 2.4rem 2.4rem;
                    }
                    @include sp {
                        margin: 0 0 2.4rem 0;
                    }
                }
                &:nth-child(2n+1) {
                    @media screen and (max-width:1023px) {
                        margin: 0 0 2.4rem 0;
                    }
                    @include sp {
                        margin: 0 0 2.4rem 0;
                    }
                }
                .card {
                    background-color: #fff;
                    border-radius: 8px;
                    a {
                        display: flex;
                        flex-direction: column-reverse;
                        .card-head {
                            padding: 0 16px 24px 16px;
                            &_info {
                                display: flex;
                                justify-content: space-between;
                                margin: 0 0 12px;
                                .c-date {
                                    font-size: 12px;
                                    color: $orange;
                                }
                                .c-tag {
                                    border: 1px solid #65BBB1;
                                    border-radius: 4px;
                                    width: 72px;
                                    text-align: center;
                                    justify-content: center;
                                    display: flex;
                                    span {
                                        color: #65BBB1;
                                        font-size: 10px;
                                        line-height: 2;
                                    }
                                }
                            }
                        }
                        .card-thumbnail {
                            margin: 0 0 18px;
                        }
                    }
                }
            }
        }
    }
    .p-newsList__pager {
        margin: 2.4rem 0 10rem;
    }
}