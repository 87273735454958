.payment ,.remark {
    margin: 0 0 8rem;
    .credit {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 1rem;
    }
}
.payment {
    dl {
        dd {
            .c-formRadio {
                @include sp {
                    display: block;
                }
                &__label {
                    &:nth-of-type(2) {
                        @include sp {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    &-branch {
        &_bank {
            ul {
                li {
                    margin: 0 0 1.6rem;
                }
            }
        }
        &_credit {
            .credit-wrap{
                display: flex;
                justify-content: space-between
                ;
                @include sp {
                    display: block;
                }
                dl {
                    width: 48%;
                    @include sp {
                        width: 100%;
                    }
                }
            }
            .creditinfo-wrap {
                display: flex;
                @include ts {
                    display: block;
                }
                dl {
                    width: 45%;
                    @include ts {
                        width: 100%;
                    }
                }
                .date {
                    dd {
                        display: flex;
                        .c-formSelect {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0;
                            @include sp {
                                width: 50%;
                            }
                            .select {
                                width: 160px;
                                @include ts {
                                    width: 200px;
                                }
                            }
                            span {
                                padding: 0 16px;
                            }
                        }
                    }
                }
                .code {
                    width: 160px;
                    position: relative;
                    @include ts {
                        width: 200px;
                    }
                    dt {
                        position: relative;
                        i {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(0 , -50%);
                            cursor:pointer;
                        }
                    }
                    .credit-help {
                        position: absolute;
                        top: -235%;
                        right: -70%;
                        display: none;
                    }
                }
            }
        }
    }
}