.p-header{
    width: 100%;
    padding: 3.2rem 0 2.5rem;
    @include tl{
        padding: 2rem 0;
    }
    &__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    &__title{
        margin-right: 5rem;
        @include ps{
            margin-right: 0rem;
        }
        @include tl{
            margin: 0 auto;
        }
        h1{
            height: 6.3rem;
            @include ps{
                height: 5.3rem;
            }
        }
        img{
            width: auto;
            height: 100%;
            display: inline-block;
        }
    }
    &__menu{
        display: flex;
        ul{
            display: flex;
            justify-content: flex-start;
            li,a{
                font-size: 1.8rem;
                letter-spacing: .01em;
                font-weight: $b;
                position: relative;
                @include ps{
                    font-size: 1.5rem;
                }
            }
        }
        &.-gnav{
            @include tl{
                display: none;
            }
            ul{
                li{
                    padding: 0 1.2rem;
                    border-left: 1px dashed $orange;
                    &:first-child{
                        border-left: none;
                    }
                }
            }
        }
        &.-mypage{

            ul{
                li{
                    a{
                        padding-left: 2rem;
                    }
                    .icon{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1.6rem;
                        height: 1.6rem;
                        display: inline-block;
                        @include tl{
                            width: 2rem;
                            height: 2rem;
                        }
                        img{
                            height: 100%;
                            width: auto;
                        }
                    }
                    .txt{
                        @include tl{
                            display: none;
                        }
                    }
                }
                li,a{
                    color: $orange;
                }
            }
        }
    }
    &__burger{
        display: none;
        width: 5.3rem;
        height: 5.3rem;
        position: absolute;
        top: 3.2rem;
        left: 0;
        cursor: pointer;
        @include tl{
            display: block;
            top: 2rem;
        }
        div{
            width: 20px;
            height: 2px;
            background-color: $brown;
            border-radius: 1px;
            position: absolute;
            top: calc(50% - 1px);
            right: calc(50% - 10px);
            transition: .3s;
            &:first-child{
                transform: translateY(-6px);
            }
            &:nth-child(2){
                transform: translateY(0);
            }
            &:last-child{
                transform: translateY(6px);
            }
        }
        &.is_active{
            div{
                &:first-child{
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    display: none;
                }
                &:last-child{
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
