.p-footer{
    margin: 0 auto;
    max-width: 1260px;
    padding: 5rem 0;
    border-top: 1px solid #D6C3A7;
    &__col2--pc{
        @include col2(768px);
    }
    &__fb{
        margin-bottom: 1rem;
        width: 40.3636%;
        @include ts{
            width: 100%;
        }
        iframe{
            width: 100%;
            height: 240px;
        }
    }
    &__txt{
        width: 54.5454%;
        padding-top: 1rem;
        @include ts{
            width: 100%;
        }
        .area1{
            margin-bottom: 4rem;
            @include col2(768px);
            &__right{
                width: 45%;
                padding-top: 1.5rem;
                @include ts{
                    width: 100%;
                }
            }
            &__left{
                width: 46.333%;
                @include ts{
                    width: 100%;
                }
                .logo{
                    @include ts{
                        height: 6.2rem;
                    }
                    img{
                        @include ts{
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
            .address, .tel{
                margin-top: .6rem;
                font-size: 1.4rem;
                letter-spacing: .01em;
            }
        }
        .area2{
            ul{
                display: flex;
                justify-content: flex-start;
                @include ts{
                    display: block;
                }
                li{
                    margin-bottom: 2rem;
                    padding: 0 1.2rem;
                    border: dashed $orange;
                    border-width: 0 1px 0 0;
                    line-height: 2rem;
                    @include ts{
                        border-width: 0 0 1px 0;
                        padding: 0 0 1rem;
                    }
                    &:first-child{
                        padding-left: 0;
                        border-left: none;
                    }
                }
            }
        }
    }
}