.p-newsList{
    @extend .c-box--white;
    padding: 1.6rem 3rem;
    &__list{
        li{
            border-bottom: 1px dotted $orange;
            a{
                padding: 1.2rem 0 1.3rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include ts{
                    display: block;
                }
                .date{
                    width: 120px;
                    color: $orange;
                    font-size: 1.2rem;
                    @include ts{
                        margin-bottom: .5rem;
                    }
                }
                .title{
                    width: calc(100% - 120px);
                    @include ts{
                        width: 100%;
                    }
                }
            }
        }
    }
    &__pager{
        margin: 4.8rem 0 3rem;
    }
}