/*
背景にイラスト設置

Markup:
<div class="c-background">
    <div class="c-background__imageContainer">
        <div class="bg bg--1"></div>
        <div class="bg bg--2"></div>
    </div>
    <div class="c-background__contentsContainer">
        <h2>コンテンツタイトル</h2>
        <p>コンテンツテキスト</p>
    </div>
</div>
*/
.c-background{
    position: relative;
    overflow: hidden;
    &__imageContainer{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 0;
        transform: translateX(-50%);
        .bg{
            position: absolute;
            z-index: -1;
        }
    }
    &__contentsContainer{
        position: relative;
        z-index: 1;
    }
}