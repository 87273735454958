.p-subpageMv{
    height: 32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../img/subpage_mv.png) center center / cover;
    @include ts{
        height: 24rem;
    }
    &__title{
        color: $orange;
        font-size: 3.4rem;
        @include ts{
            font-size: 2.4rem;
        }
    }
}