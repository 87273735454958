#assessment_kit{
    margin-top: 7rem;
    margin-bottom: 9rem;
}
#kit_application{
    margin-bottom: 9rem;
}
#kit_voice{
    margin-bottom: 9rem;
}
#assessment_resume{
    margin-bottom: 10rem;
    .p-blockC{
        margin: 0 auto;
        max-width: 750px;
    }
}
#resume_detail{
    margin-top: 0rem;
}
.assessment{
    &-tabArea{
        padding-top: 4.8rem;
        text-align: center;
        &-wrap{
            display: inline-block;
        }
        &-tab{
            @extend .p-category-wrap;
            width: auto;
            &_item{
                @extend .p-category-wrap_item;
            }
        }
    }
    &-intro{
        margin-bottom: 9.5rem;
        h2{
            margin-bottom: 2.5rem;
        }
    }
    &-application{
        .p-blockA{
            &__img{
                width: 51.1%;
                @include ts{
                    width: 100%;
                }
            }
            &__txt{
                width: 42.9%;
                padding-top: 1.2rem;
                padding-right: 0;
                @include ts{
                    width: 100%;
                }
                h3{
                    margin-bottom: 1.5rem;
                }
                p{
                    letter-spacing: -.5px;
                }
            }
        }
    }
    &-voice{
        h3{
            margin-bottom: 4rem;
            text-align: center;
        }
        &__list{
            margin-bottom: 2rem;
            ul{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li{
                    margin-bottom: 4rem;
                    width: 32%;
                    background-color: #fff;
                    padding: 1.6rem 1.5rem;
                    border-radius: 8px;
                    position: relative;
                    line-height: 2;
                    @include ts{
                        width: 49%;
                    }
                    @include sp{
                        width: 100%;
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border: 10px solid transparent;
                        border-top: 15px solid #fff;
                        position: absolute;top: 100%;left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        &__img{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .img{
                width: 49%;
            }
        }
    }
    &-detail{
        h3{
            margin-bottom: 2rem;
        }
        .c-tableC{
            padding: 2rem 3rem;
            &_item{
                padding: .8rem 0;
                &:last-child{
                    border-bottom: none;
                }
            }
            dt,dd{
                line-height: 2;
            }
            dd.content{
                padding: 0;
                a{
                    color: $orange;
                    text-decoration: underline;
                }
                dl{
                    margin-bottom: .6rem;
                }
            }
            dt.main_title{
                @include ts{
                    margin-bottom: .5rem;
                }
            }
            dd.sub_content{
                padding: 0!important;
            }

            .col2--pc{
                @include col2(768px);
                dl{
                    width: 48%;
                    @include ts{
                        margin-bottom: 2rem;
                        width: 100%;
                    }
                }
            }
        }
        &__btn{
            margin-top: 3rem;
            text-align: center;
        }
    }
    &-form{
        padding-bottom: 10rem;
        @include ts{
            padding-bottom: 5rem;
        }
        .col2--pc{
            @include col2(768px);
            &__item{
                width: 49%;
                @include ts{
                    margin-bottom: 2rem;
                    width: 100%;
                }
            }
        }
        section{
            margin-bottom: 7rem;
        }
        .c-box--white{
            margin-top: 3.4rem;
            padding: 3rem 4rem;
            @include ts{
                padding: 3rem 2rem;
            }
        }
        h2{
            margin-bottom: 2.5rem;
        }
        h4{
            margin-bottom: .6rem;
        }
        dl{
            margin-bottom: 2rem;
            padding-left: .5rem;
            dt,dd{
                font-size: 1.4rem;
            }
            dt{
                margin-bottom: .8rem;
                color: $orange;
            }
            dd{
                .c-formTextarea{
                    textarea{
                        height: 16rem;
                    }
                }
                .select{
                    @include ts{
                        width: 80px;
                    }
                }
            }
        }
        .table{
            width: 100%;
            overflow-x: scroll;
        }
        table{
            width: 100%;
            white-space: nowrap;
            thead{
                border-bottom: 1px solid #D6C3A7;
            }
            tfoot{
                border-top: 1px solid #D6C3A7;
            }
            tr{
                th,td{
                    width: 115px;
                    text-align: center;
                    &:first-child{
                        width: auto;
                        text-align: left;
                    }
                    .select{
                        margin: 0 auto;
                        width: 8rem;
                    }
                }
                th{
                    padding-bottom: 1rem;
                    color: $orange;
                    font-size: 1.4rem;
                }
                td{
                    padding: 1.5rem 0 0;
                    vertical-align: middle;
                }
                &:last-child td{
                    padding-bottom: 1.5rem;
                }
            }
            tfoot{
                th,td{
                    padding: 1.5rem 0;
                    vertical-align: middle;
                    text-align: right!important;
                }
                th{
                    padding-top: 1.7rem;
                    padding-right: 4.5rem;
                }
            }
        }
        &__btn{
            margin-top: 5rem;
            text-align: center;
            .backbtn{
                @include ts{
                    margin-bottom: 2rem;
                }
            }
        }
    }
    &-thanks{
        &-container{
            padding: 10rem 0;
            text-align: center;
        }
        &__btn{
            margin-top: 5rem;
        }
    }
}