.u-none{
    &--768{
        @media (max-width:768px){
            display: none;
        }
    }
}
.u-block{
    &--768{
        display: none;
        @media (max-width:768px){
            display: block;
        }
    }
    &--480{
        display: none;
        @media (max-width:480px){
            display: block;
        }
    }
}

.flexbetweens {
    display: flex;
    justify-content: space-between;
}

.flex {
    display: flex;
}