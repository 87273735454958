.p-contact{
    &__desc{
        padding-top: 2rem;
        text-align: center;
    }
    &__form{
        margin-top: 8rem;
        @include ts{
            margin-top: 6rem;
        }
    }
    dl{
        margin-bottom: 2.2rem;
    }
    dt,dd{
        font-size: 1.4rem;
    }
    dt{
        margin-bottom: .7rem;
        color: $orange;
    }
    &__btn{
        margin-top: 4.8rem;
        text-align: center;
        @include ts{
            margin-top: 3rem;
        }
        .btn{
            max-width: 48rem;
        }
        .backbtn{
            max-width: 32rem;
            @include ts{
                max-width: 34rem;
            }
        }
        .submitbtn{
            max-width: 34rem;
        }
        .homebtn{
            max-width: 34rem;
        }
    }

    &__confirm{
        margin-top: 4.8rem;
        .whitebox{
            padding: 4rem 4rem 2.3rem;
            background-color: #fff;
            border-radius: 20px;
            @include ts{
                padding: 4rem 2rem 2.3rem;
            }
        }
    }
}