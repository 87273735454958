.c-tableA{
    padding: 1.5rem 3rem 1.5rem;
    background-color: #fff;
    border-radius: 12px;
    table{
        width: 100%;
        line-height: 2;
        tr{
            border-bottom: 1px dashed $orange;
            &:last-child{
                border: none;
            }
        }
        th,td{
            @include ts{
                display: block;
            }    
        }
        th{
            width: 120px;
            padding: .8rem 0;
            color: $orange;
            font-size: 1.6rem;
            @include ts{
                padding-bottom: 0;
            }
        }
        td{
            padding: 1.1rem 0 .8rem;
            font-size: 1.4rem;
            line-height: 2;
            @include ts{
                padding-top: 0;
            }
        }
        dl{
            margin-bottom: 1rem;
            dt{
                color: $orange;
                line-height: 1.9;
            }
        }
    }
}

.c-tableB{
    padding: 1.5rem 3rem .5rem;
    background-color: #fff;
    border-radius: 12px;
    @include sp {
        padding: 1.5rem 1.5rem .5rem;
    }
    &_item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed $orange;
        padding: .8rem 0;
        @include sp {
            display: block;
            padding: 1.6rem 0;
        }
        &:last-child{
            border: none;
        }
        .title {
            color: $orange;
            @include sp {
                margin: 0 0 4px;
            }
        }
        .content {
            width: 79.7%;
            @include sp {
                width: 100%;
            }
            .detail {
                margin: 10px 0 0;
                padding: 10px 0 10px 10px;
                background-color: #F5F1ED;
                font-size: 1rem;
            }
            ul {
                @include sp {
                    padding: 0 0 0 20px;
                }
                li {
                    list-style: disc;
                    margin: 0 0 12px;
                    a {
                        color: $orange;
                        position: relative;
                        @include sp {
                            font-size: 12px;
                        }
                        @include ss {
                            font-size: 10px;
                        }
                    }
                    ._blank {
                        &::after {
                            content: "";
                            background-image: url("../img/link_window.png");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 50%;
                            right: -30px;
                            transform: translate(0 , -50%);
                        }
                    }
                }
            }
        }
    }
}

.c-tableC {
    padding: 1.6rem 3.2rem;
    background-color: #fff;
    border-radius: 12px;
    @include sp {
        padding: 1.2rem 2.4rem;
    }
    &_item {
        display: flex;
        justify-content: space-between;
        border-bottom: dotted 1px $orange;
        padding: 1.6rem 0;
        @include sp {
            display: block;;
        }
        .main_title {
            color: $orange;
            width: 11%;
            @include ts {
                width: 15%;
            }
            @include sp {
                width: 100%;
                margin: 0 0 1.6rem;
            }
        }
        .content {
            font-size: 14px;
            width: 87%;
            @include ts {
                width: 80%;
            }
            @include sp {
                width: 100%;
            }
            .sub_title {
                color: $orange;
                padding: .2rem 0 0;
            }
            .sub_content {
                padding: 1.6rem 0;
                &:last-child {
                    padding: 1.6rem 0 0;
                }
            }
            .flex {
                display: flex;
                align-items: center;
                .sub_title {
                    margin: 0 1.6rem 0 0;
                }
            }
            .link{
                margin: 1.2rem 0 0;
                a {
                    font-size: 14px;
                    position: relative;
                    color: $orange;
                }
                ._blank {
                    text-decoration: underline;
                    &::after {
                        content: "";
                        background-image: url("../img/link_window.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: 50%;
                        right: -30px;
                        transform: translate(0 , -50%);
                    }
                }
            }
        }
    }
}