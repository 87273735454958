.p-seminr_entry{
    margin: 0 0 10rem;
    &__form{
        margin-top: 4rem;
        .entry-column {
            margin: 0 0 8rem;
        }
        input {
            background-color: #fff;
        }
    }
    &__desc{
        margin-bottom: 5rem;
    }
    h2{
        margin-bottom: 3.2rem;
        font-size: 2.4rem;
    }
    p{
        font-size: 1.4rem;
        text-align: center;
    }
    dl{
        margin-bottom: 2rem;
    }
    dt,dd{
        font-size: 1.4rem;
    }
    dt{
        margin-bottom: 1rem;
        color: $orange;
    }
    &__btn{
        margin-top: 4rem;
        text-align: center;
    }
    &__link{
        margin-top: 2rem;
        text-align: center;
        a{
            color: $orange;
            font-size: 1.6rem;
        }
    }
    .c-formSelect {
        .select {
            max-width: 100%;
        }
    }
    .p-login__btn {
        display: flex;
        justify-content: center;
        div {
            &:first-child {
                margin: 0 1.6rem 0 0;
            }
        }
    }
}