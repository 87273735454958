.p-history{
    @extend .c-box--white;
    overflow: hidden;
    &__tab{
        ul{
            display: flex;
            li{
                width: 50%;
                text-align: center;
                a{
                    height: 8.2rem;
                    background-color: #D5CCC4;
                    display: block;
                    font-size: 1.8rem;
                    line-height: 8.2rem;
                    @include ts{
                        height: 6.2rem;
                        font-size: 1.6rem;
                        line-height: 6.2rem;
                    }
                    &.active{
                        background-color: #fff;
                        color: $orange;
                    }
                }
            }
        }
    }
    &__list{
        padding: 2.7rem 3.2rem 1.7rem;
        @include ts{
            padding: 1.7rem 2rem;
        }
        .order-date{
            margin-bottom: 1rem;
            padding: 1.2rem 1.6rem;
            background-color: #F5F1ED;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            font-size: 1.1rem;
            &__link{
                a{
                    color: $orange;
                    &::after{
                        margin-top: -.2rem;
                        margin-left: .4rem;
                        content: '';
                        display: inline-block;
                        width: .7rem;
                        height: .7rem;
                        border: solid $orange;
                        border-width: 2px 2px 0 0;
                        vertical-align: middle;
                        transform-origin: center center;
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .pager{
            padding: 3.8rem 0;
        }
    }
}

.p-historyDetail{
    @extend .c-box--white;
    padding: 3.2rem 3.4rem 1.8rem;
    h2{
        padding: .9rem 1.6rem .8rem;
        background-color: #F5F1ED;
        border-radius: 4px;
        font-size: 1.4rem;
    }
    dl{
        padding: .9rem 0 .7rem;
        display: flex;
        font-size: 1.4rem;
        line-height: 2.2;
        border-bottom: 1px solid #D5CCC4;
        &:last-child{
            border-bottom: none;
        }
        dt{
            width: 17.6%;
        }
        dd{
            width: 82.4%;
            line-height: 2.2;
            a{
                color: $orange;
                text-decoration: underline;
            }
            span{
                &.tag{
                    margin-right: .8rem;
                    width: 7.2rem;
                    padding: .3rem 0;
                    background-color: #65BBB1;
                    border-radius: 4px;
                    display: inline-block;
                    color: #fff;
                    font-size: 1rem;
                    line-height: 1;
                    text-align: center;
                }
                &.small{
                    font-size: 1.1rem;
                }
            }
            
        }
    }
    .p-orderList{
        li{
            line-height: 1.6;
        }
    }
}