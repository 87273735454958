.l-inner{
    box-sizing: border-box;
    margin: 0 auto;
    // width: calc(100% - 44px);
    width: 100%;
    max-width: 1366px;
    padding: 0;
    position: static;
}
.l-inner1260{
    box-sizing: border-box;
    margin: 0 auto;
    // width: calc(100% - 44px);
    // width: 94%;
    width: calc(100% - 60px);
    max-width: 1260px;
    padding: 0;
    position: static;
    @include ts{
        width: calc(100% - 30px);
    }
}
.l-inner1100{
    box-sizing: border-box;
    margin: 0 auto;
    // width: calc(100% - 44px);
    width: calc(100% - 60px);
    max-width: 1100px;
    padding: 0;
    position: static;
    @include ts{
        width: calc(100% - 30px);
    }
}
.l-inner940{
    box-sizing: border-box;
    margin: 0 auto;
    // width: calc(100% - 44px);
    // width: 90%;
    width: calc(100% - 60px);
    max-width: 940px;
    padding: 0;
    position: static;
    @include ts{
        width: calc(100% - 30px);
    }
}

.l-inner640{
    box-sizing: border-box;
    margin: 0 auto;
    width: calc(100% - 60px);
    max-width: 640px;
    padding: 0;
    position: static;
}