@mixin col2($bp:0){
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width:$bp){
        display: block;
    }
}
@mixin col3($bp:0){
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width:$bp){
        display: block;
    }
}

@mixin bg_base(){
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    + * {
        position: relative;
        z-index: 1;
    }
}