.c-status{
    display: flex;
    align-items: center;
    line-height: 3rem;
    &__tag{
        margin-right: .8rem;
        width: 7.2rem;
        padding: .3rem 0;
        background-color: #A39C95;
        border-radius: 4px;
        display: inline-block;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        text-align: center;
        &.-blue{
            background-color: #65BBB1;
        }
    }
    &__price{
        font-size: 1.1rem;
    }
}