// @keyframes kvbg_pc {
//     0% {
//         opacity: 0;
//         background-size: left center;
//     }
//     4% {
//         opacity: 1;
//         background-position: left center;
//     }
//     96% {
//         background-position: right center;
//         opacity: 1;
//     }
//     100% {
//         background-position: right center;
//         opacity: 0;
//     }
// }
// @keyframes kvbg_tb {
//     0% {
//         opacity: 0;
//         background-position: left center;
//     }
//     2% {
//         opacity: 1;
//         background-position: left center;
//     }
//     98% {
//         background-position: right center;
//         opacity: 1;
//     }
//     100% {
//         background-position: right center;
//         opacity: 0;
//     }
// }


// @keyframes fadeIn {
//     0% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
// @keyframes fadeOut {
//     0% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }

@keyframes nyuruIn {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0%);
    }
}