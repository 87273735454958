// <h2 class="c-title24">タイトルテキスト</h2>
.c-title24{
    color: $orange;
    font-size: 2.4rem;
    line-height: 2;
    &.-tac{
        text-align: center;
    }
}
.c-title24--brown{
    color: $color_base;
    font-size: 2.4rem;
    line-height: 2;
}

.c-title32{
    color: $orange;
    font-size: 3.2rem;
    line-height: 2;
    @include ts{
        font-size: 2.4rem;
    }
    span{
        display: block;
        color: #DBA677;
        font-size: 2rem;
    }
}