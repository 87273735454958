.c-wm-vrl{
    width: 100%;
    // display: inline-block;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-align: left;
    letter-spacing: .5rem;
    column-count: 1;
    @include sp{
        column-gap: 3rem;
    }
    // p{
    //     margin-left: 4rem;
    //     @include sp{
    //         margin-left: 2rem;
    //     }
    // }
}
