.p-thanks{
    &__desc {
        margin: 10rem 0 4.8rem;
        p {
            text-align: center;
        }
    }
    &__btn {
        text-align: center;
        margin: 0 0 10rem;
    }
}