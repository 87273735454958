.p-login{
    height: calc(100vh - 123px);
    display: flex;
    justify-content: center;
    align-items: center;
    @include tl{
        height: calc(100vh - 93px);
    }
    &__wrapper{
        max-width: 480px;
        width: calc(100% - 30px);
    }
    &__desc{
        margin-top: 2rem;
        text-align: center;
    }
    &__form{
        margin-top: 4rem;
        .col2--pc{
            @include col2(768px);
            &__item{
                width: 49%;
                @include ts{
                    margin-bottom: 2rem;
                    width: 100%;
                }
            }
        }
        .col3--pc{
            @include col3(768px);
            &__item{
                width: 31.5%;
                @include ts{
                    margin-bottom: 2rem;
                    width: 100%;
                }
            }
        }
    }
    h2{
        margin-bottom: 1rem;
        font-size: 2.4rem;
        text-align: center;
    }
    p{
        font-size: 1.4rem;
        text-align: center;
    }
    dl{
        margin-bottom: 2rem;
    }
    dt,dd{
        font-size: 1.4rem;
    }
    dt{
        margin-bottom: 1rem;
    }
    &__btn{
        margin-top: 4rem;
        text-align: center;
    }
    &__link{
        margin-top: 2rem;
        text-align: center;
        a{
            color: $orange;
            font-size: 1.6rem;
        }
    }
}