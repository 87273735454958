.p-edit{
    @extend .c-box--white;
    padding: 3.5rem 4rem 8rem;
    @include ts{
        padding: 3.5rem 2rem 6rem;
    }
    &.-password{
        padding-bottom: 4rem;
    }
    h2{
        margin-bottom: 2.8rem;
        font-size: 2.4rem;
    }
    dl{
        margin-bottom: 2rem;
        dt,dd{
            font-size: 1.4rem;
        }
        dt{
            margin-bottom: .8rem;
        }
    }
    &__btn{
        margin-top: 5rem;
        text-align: center;
    }
}