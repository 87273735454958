#service1_content1{
    margin-bottom: 9rem;
}
#service1_content2{
    margin-bottom: 1.5rem;
}
#service1_content3{
}
#service2_content1{
    margin-bottom: 7.6rem;
}
#service2_content2{
    margin-bottom: 10.9rem;
}
#service2_content3{
    margin-bottom: 10.8rem;
}
#service2_content4{
    margin-bottom: 8rem;
}

.supporting-member{
    padding: 8.8rem 0 0;
    .p-blockC{
        margin-bottom: 5.1rem;
        h2{
            margin-bottom: 1.4rem
        }
        p{
            line-height: 1.8;
        }
    }
}

.service-family{
    padding: 0;
    &__col2--pc{
        margin-top: 4.8rem;
        @include col2(768px);
    }
    &__item{
        width: 47.2727%;
        margin-bottom: 7.5rem;
        @include ts{
            width: 100%;
        }
    }
    .p-blockC{
        h2{
            margin-bottom: 1.4rem
        }
        p{
            line-height: 1.8;
        }
    }
    .p-blockB{
        &__img{
            margin-bottom: 1rem;
        }
        &__txt{
            padding-right: 0;
            h3{
                margin-bottom: .6rem;
            }
        }
    }
}

.service-self{
    padding: 0 0 5rem;
    .p-blockC{
        h2{
            margin-bottom: 1.4rem
        }
        p{
            line-height: 1.8;
        }
    }
    .c-imgList{
        margin-top: 7.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include ts{
            margin-top: 4rem;
        }
        &__item{
            margin-bottom: 2rem;
            width: 31.5%;
            @include ts{
                width: 48%;
            }
            @include sp{
                width: 100%;
            }
        }
    }
}

.service2-a{
    padding: 10rem 0 0;
    &.c-background{
        .c-background__imageContainer{
            width: 1508px;
            .bg--1{
                width: 278px;
                position: absolute;
                top: 27rem;
                right: 0rem;
            }
            .bg--2{
                width: 286px;
                position: absolute;
                top: 45rem;
                left: 4rem;
            }
        }
    }
    .p-blockA{
        h2{
            margin-bottom: 1.2rem;
        }
    }
}
.service2-b{
    padding: 0 0 14rem;
    @include ts{
        padding-bottom: 7rem;
    }
    .p-blockC{
        margin: 0 auto;
        max-width: 642px;
        h2{
            margin-bottom: 1.5rem;
        }
        p{
            margin-bottom: 2.9rem;
            line-height: 1.8;
        }
    }
}