.p-info{
    @extend .c-box--white;
    padding: 3.5rem 4rem 2rem;
    position: relative;
    @include ts{
        padding: 3.5rem 2rem 2rem;
    }
    h2{
        margin-bottom: 2.5rem;
        font-size: 2.4rem;
    }
    &__btn{
        position: absolute;
        top: 4.3rem;
        right: 4rem;
    }
    .col2--pc{
        @include col2(768px);
        justify-content: flex-start;
        dl{
            width: 50%;
        }
    }
    dl{
        margin-bottom: 2.1rem;
        line-height: 1.75;
        dt{
            color: $orange;
            font-size: 1.4rem;
            a{
                margin-left: 1rem;
            }
        }
        .change-password{
            color: $color_base;
            font-size: 1.2rem;
            text-decoration: underline;
        }
    }
}