.p-postsList{
    background-color: #fff;
    border-radius: 12px;
    h2{
        margin-bottom: 3rem;
        text-align: center;
        @include tl{
            text-align: left;
        }
    }
    li{
        margin-bottom: 2rem;
        &:last-child{
            margin-bottom: 0;
        }
        a{
            display: flex;
            align-items: center;
            @include sp{
                flex-wrap: wrap;
            }
        }
        .date{
            margin-right: 1rem;
            font-size: 1.4rem;
        }
        .cat{
            font-size: 1rem;
            &__item{
                width: 7rem;
                border: 1px solid #65BBB1;
                border-radius: 4px;
                color: #65BBB1;
                text-align: center;
            }
        }
        .title{
            margin-left: 2rem;
            font-size: 1.6rem;
            @include sp{
                margin-left: 0;
                width: 100%;
            }
        }
    }
}