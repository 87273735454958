.p-eservice {
    .c-title24 {
        margin: 0 0 48px;
    }
    .mission {
        margin: 0 0 12.8%;
        .l-inner640 {
            width: 100%;
        }
        &-wrap {
            .c-background__imageContainer {
                width: 1675px;
                @include tl {
                    // display: none;
                }
                .bg--1 {
                    top: 3%;
                }
            }
        }
    }
    .origin {
        &_wrap {
            margin: 0 auto 12.8%;
            @include tl {
                margin: 0 auto 48.8%;
            }
            @include sp {
                margin: 0 auto 100%;
            }
            &-content {
                display: flex;
                justify-content: space-between;
                @include tl {
                    flex-direction: column-reverse;
                }
                .img-wrap {
                    position: relative;
                    width: 50%;
                    @include tl {
                        width: 100%;
                    }
                    &_item {
                        position: absolute;
                    }
                    .img-01 {
                        top: 0;
                        left: 0;
                        z-index: 1;
                        @include ts {
                            width: 40%;
                        }
                        @include sp {
                            width: 70%;
                        }
                    }
                    .img-02 {
                        top: 35%;
                        left: 43%;
                        z-index: 2;
                        @include tl {
                            left: 36%;
                            margin: 16% 0 0;
                        }
                        @include ts {
                            width: 30%;
                        }
                        @include sp {
                            left: 52%;
                            width: 50%;
                            margin: 36% 0 0;
                        }
                    }
                    .img-03 {
                        top: 61%;
                        left: 5%;
                        z-index: 1;
                        @include tl {
                            left: 60%;
                            transform: scale(-1, 1);
                            margin: 2% 0 0;
                        }
                        @include sp {
                            left: 5%;
                            width: 60%;
                            margin: 68% 0 0;
                            transform: scale(1, 1);
                        }
                    }
                }
                .text {
                    width: 47%;
                    @include tl {
                        width: 100%;
                    }
                    p {
                        margin: 0 0 9%;
                        @include tl {
                            margin: 0 0 3%;
                        }
                    }
                }
            }
        }
    }
}