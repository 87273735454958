.c-date{
    font-size: 12px;
    color: $orange;
}

.c-tag {
    border: 1px solid #65BBB1;
    border-radius: 4px;
    width: 72px;
    text-align: center;
    justify-content: center;
    display: flex;
    span {
        color: #65BBB1;
        font-size: 10px;
        line-height: 2;
    }
}