.p-orderList{
    li{
        width: 100%;
        padding: 1.4rem 0 .8rem;
        line-height: 2;
        border-bottom: 1px solid #D5CCC4;
        position: relative;
        font-size: 1.4rem;
        &:first-child{
            padding-top: .4rem;
        }
        &:last-child{
            padding-bottom: .4rem;
            border-bottom: none;
        }
        a{
            color: $color_base;
        }
        .title{
            margin-bottom: 0rem;
            line-height: 1.4;
            a{
                color: $orange;
                text-decoration: underline;
            }
        }

    }
    &.-product{
        margin-bottom: 2rem;
    }
    &.-arrowed{
        li{
            &::after{
                margin-top: -.2rem;
                margin-left: .4rem;
                content: '';
                display: inline-block;
                width: .7rem;
                height: .7rem;
                border: solid $brown;
                border-width: 2px 2px 0 0;
                vertical-align: middle;
                transform-origin: center center;
                transform: rotate(45deg);
                position: absolute;
                top: 50%;
                right: 1.5rem;
            }
        }
    }
}