.p-banner{
    padding: 13rem 0 9rem;
    position: relative;
    background-color: #fff;
    @include ts{
        padding: 7rem 0 6rem;
    }
    &::before{
        content: '';
        width: 100%;
        height: 3rem;
        position: absolute;
        top: 0;
        left: 0;
        background: repeat-x url(../img/home/home_map_bg_bottom.png) bottom center / 1366px;
    }
    &::after{
        content: '';
        width: 100%;
        height: 3rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background: repeat-x url(../img/footer_bg.png) top center / 1366px;
    }
    &__list{
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        li{
            width: 32.5454%;
            margin-left: 1.1818%;
            margin-bottom: 2%;
            @include ts{
                width: 49%;
                margin-left: 2%;
            }
            &:nth-child(3n-2){
                margin-left: 0;
                @include ts{
                    margin-left: 2%;
                }
            }
            &:nth-child(2n-1){
                @include ts{
                    margin-left: 0;
                }
            }
        }
    }
}