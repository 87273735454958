.p-article{
    @extend .c-box--white;
    padding: 4.2rem 4.8rem 6.2rem;
    margin: 0 0 7.5rem;
    @include sp {
        padding: 2rem;
    }
    h1{
        margin-bottom: 1rem;
        font-size: 2.6rem;
    }
    &__header{
        margin-bottom: 3rem;
    }
    &__thumbnail {
        margin: 3.6rem 0 4.0rem;
    }
    &__meta{
        .date{
            margin-right: 2.4rem;
            display: inline-block;
            color: $orange;
            font-size: 1.4rem;
        }
        .cat{
            display: inline-block;
            &__item{
                width: 9.6rem;
                padding: .7rem 0 .6rem;
                border: 1px solid #65BBB1;
                border-radius: 4px;
                color: #65BBB1;
                font-size: 1.2rem;
                text-align: center;
                line-height: 1;
            }
        }
    }
    &__contents{
        p{
            a{
                color: $orange;
                text-decoration: underline;
            }
        }
    }
    &__info {
        margin: 3.2rem 0 0;
        h2 {
            margin: 0 0 2.4rem;
            font-size: 2.0rem;
            color: $orange;
        }
        p {
            margin: 0 0 4.0rem;
            a{
                color: $orange;
                text-decoration: underline;
            }
        }
        dl {
            margin: 0 0 2.4rem;
        }
    }
}