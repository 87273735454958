#sidebar{
    margin-top: 3rem;
    @include ts{
        margin: 0 auto;
        width: calc(100% - 30px);
    }
}
.p-sidebar{
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-top: 8px solid $orange;
    border-radius: 8px;
    ul{
        li{
            border-bottom: 1px dotted $orange;
            &:last-child{
                border-bottom: none;
            }
            a{
                padding: 1rem 0;
                display: block;
                font-size: 1.4rem;
                &.active{
                    color: $orange;
                }
            }
        }
    }
}