@charset "utf-8";

// config ------------------------------------------------------------
@import "partials/config/_breakpoint";
@import "partials/config/_font";
@import "partials/config/_variable";

/* foundation--------------------------------------------------------- */
@import "partials/foundation/_destyle";
@import "partials/foundation/_base";
@import "partials/foundation/_mixin";
@import "partials/foundation/_keyframe";

/* layout------------------------------------------------------------ */
@import "partials/layout/_layout";
@import "partials/layout/_wrapper";
@import "partials/layout/_inner";
@import "partials/layout/_col";

/* object------------------------------------------------------------ */
/* object/component ボタンなど、再利用できる小さな単位のモジュールを定義します ---------------------------- */
@import "partials/object/component/_writing-mode";
@import "partials/object/component/_btn";
@import "partials/object/component/_form";
@import "partials/object/component/_title";
@import "partials/object/component/_table";
@import "partials/object/component/_pager";
@import "partials/object/component/_breadcrumb";
@import "partials/object/component/_note";
@import "partials/object/component/_box";
@import "partials/object/component/_background";
@import "partials/object/component/_status";
@import "partials/object/component/_progress";
@import "partials/object/component/_date-tag";

/* object/project 記事一覧や、ユーザープロフィール、画像ギャラリーなど、繰り返し登場するコンテンツを構成する要素 ------------------------------ */
@import "partials/object/project/_header";
@import "partials/object/project/_footer";
@import "partials/object/project/_drawer";
@import "partials/object/project/_subpageTitle";
@import "partials/object/project/_banner";
@import "partials/object/project/_block";
@import "partials/object/project/_postsList";
@import "partials/object/project/_newsList";
@import "partials/object/project/_login";
@import "partials/object/project/_contact";
@import "partials/object/project/_article";
@import "partials/object/project/_sidebar";
@import "partials/object/project/_history";
@import "partials/object/project/_orangecircle";
@import "partials/object/project/_info";
@import "partials/object/project/_order";
@import "partials/object/project/_edit";
@import "partials/object/project/_categoryList";
@import "partials/object/project/_entry";
@import "partials/object/project/_confirm";
@import "partials/object/project/_thanks";
@import "partials/object/project/_formpayment";

/* object/utility わずかなスタイルの調整のための便利クラスなど ------------------------------ */
@import "partials/object/utility/_display";

/* page 各ページ固有のクラスなど------------------------------ */
@import "partials/page/_home";
@import "partials/page/_service";
@import "partials/page/_news";
@import "partials/page/_single";
@import "partials/page/_contact";
@import "partials/page/_program";
@import "partials/page/_mypage";
@import "partials/page/_assessment";
@import "partials/page/_eservice";
@import "partials/page/_event";
@import "partials/page/_yearpass";