.p-category {
    margin: 4.8rem 0 3.2rem;
    &-wrap {
        display: flex;
        flex-wrap: wrap;
        padding: 1.4rem 0;
        width: 100%;
        background-color: #fff;
        border: solid $orange;
        border-width: .1rem .1rem .3rem .1rem;
        border-radius: .8rem;
        font-size: 1.6rem;
        &_item {
            padding: 0 3.2rem;
            border-right: 1px dotted #E8652B;
            text-decoration: underline;
            @include ts {
                padding: 0 2rem;
            }
            @include sp {
                margin: .6rem 0;
                padding: 0 1rem;
            }
        }
        .--active {
            text-decoration: none;
            a {
                color: $orange;
            }
        }
    }
}