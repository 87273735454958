.homeMv{
    &__inner{
        position: relative;
    }
    &__txt{
        width: 10%;
        position: absolute;
        top: 6%;
        right: 10%;
    }
}
.homePosts{
    padding: 5rem 0 0;
    h2{
        margin-bottom: 2.3rem;
    }
    &__col2--pc{
        @include col2(768px);
    }
    &__seminar{
        width: 57.5%;
        @include tl{
            margin-bottom: 2rem;
            width: 100%;
        }
        .p-postsList{
            padding: 1.5rem 4rem 4rem 5rem;
            position: relative;
            @include tl{
                padding: 3rem 2rem;
            }
            .btn{
                position: absolute;
                top: 2.5rem;
                right: 4rem;
                @include tl{
                    right: 2rem;
                }
            }
        }
    }
    &__news{
        width: 40.555%;
        @include tl{
            width: 100%;
        }
        .p-postsList{
            padding: 1.5rem 2rem 4rem 4rem;
            position: relative;
            @include tl{
                padding: 3rem 2rem;
            }
            .btn{
                position: absolute;
                top: 2.5rem;
                right: 2.3rem;
            }
        }
    }
}
.homeAbout{
    padding: 9rem 0 10rem;
    &.c-background{
        .c-background__imageContainer{
            width: 1643px;
            .bg--1{
                width: 100%;
                position: absolute;
                top: 3.5rem;
                right: -1rem;
            }
        }
    }
    &::after{
        content: '';
        width: 100%;
        height: 90px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: repeat-x url(../img/home/home_about_bg_bottom.png) bottom center / 1366px #fff;
    }
    h2{
        margin-bottom: 1.5rem;
    }
    p{
        line-height: 1.8;
    }
    .btn{
        // margin: 0 auto;
    }
}
.homeInitiative{
    padding-top: 7rem;
    background-color: #fff;
    &.c-background{
        .c-background__imageContainer{
            width: 1506px;
            .bg--1{
                width: 278px;
                top: 32rem;
                right: 0;
            }
        }
    }
    h2{
        margin-bottom: 3.4rem;
        text-align: center;
    }
    &__section{
        margin-bottom: 7.5rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.homeService{
    padding: 9rem 0 2.5rem;
    background-color: #fff;
    &.c-background{
        .c-background__imageContainer{
            width: 1426px;
            .bg--1{
                width: 286px;
                position: absolute;
                top: 11.5rem;
                left: 0;
            }
        }
    }
    h2{
        margin-bottom: 2rem;
        text-align: center;
    }
    &__imageContainer{
        width: 1426px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &__col2--pc{
        @include col2(768px);
    }
    &__item{
        width: 47.2727%;
        margin-bottom: 7.5rem;
        @include ts{
            width: 100%;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.homeMap{
    padding: 7.3rem 0 4.3rem;
    position: relative;
    text-align: center;
    &::before{
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        background: repeat-x url(../img/home/home_map_bg_top.png) top center / 1366px #fff;
    }
    h2{
        margin-bottom: 1.5rem;
    }
    p{
        line-height: 1.8;
    }
    &__map{
        position: relative;
        padding-right: 6%;
        @include tl{
            padding-right: 0;
        }
        .map{
            margin-top: -9.3rem;
            padding: 0 1%;
            @include sp{
                margin-top: 0;
            }
        }
        ul{
            @include tl{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }
        li{
            width: 17rem;
            text-align: left;
            position: absolute;
            @include tl{
                width: 28%;
                margin: 0 0 5% 8%;
                position: static;
            }
            @include ts{
                width: 45%;
                margin: 0 0 5% 5%;
            }
            @include sp{
                width: 100%;
                margin: 0 0 5% 0;
            }
            &:nth-child(3n-2){
                @include tl{
                    margin-left: 0;
                }
                @include ts{
                    margin-left: 5%;
                }
                @include sp{
                    margin-left: 0;
                }
            }
            &:nth-child(2n-1){
                @include ts{
                    margin-left: 0;
                }
            }
            .eng{
                padding-bottom: .2rem;
                margin-bottom: .5rem;
                font-size: 1.2rem;
                border-bottom: 1px dashed $orange;
                line-height: 1.45;
            }
            .name{
                margin-bottom: .5rem;
                font-size: 1.4rem;
            }
            .link{
                a{
                    display: inline-block;
                    height: 2rem;
                    img{
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }
        .doto{
            top: 27%;
            left: 77.2%;
        }
        .sendai{
            top: 56%;
            left: 67.2%;
        }
        .fukui{
            top: 44.5%;
            left: 42.5%;
        }
        .kanagawa{
            top: 82.3%;
            left: 45%;
        }
        .shizuoka{
            top: 61.4%;
            left: 50.5%;
        }
        .osaka{
            top: 78.6%;
            left: 27.2%;
        }
        .kyoto{
            top: 46.9%;
            left: 23.1%;
        }
        .yamaguchi{
            top: 70.1%;
            left: 9.7%;
        }
        .okinawa{
            top: 82.3%;
            left: 75.4%;
        }
    }
}

#page_home{
    .p-footer{
        border-top: none;
    }
}