.c-note{
    margin-top: .8rem;
    padding: .8rem;
    font-size: 1.1rem;
    border-radius: 4px;
    line-height: 1.5;
    &--beige{
        @extend .c-note;
        background-color: #F5F1ED;
        color: $brown;
    }
    &--red{
        @extend .c-note;
        background-color: #FFD8D8;
        color: #FF1717;
    }
}