.c-progress{
    width: 100%;
    padding: 5rem 0;
    ul{
        display: flex;
        justify-content: center;
        li{
            margin-right: 4px;
            width: 184px;
            height: 56px;
            padding-left: 28px;
            background-color: #fff;
            text-align: center;
            position: relative;
            color: #DBA677;
            line-height: 56px;
            @include ts{
                font-size: 1rem;
            }
            &:last-child{
                width: 208px;
            }
            &::before{
                content: '';
                width: 0;
                height: 0;
                border: 28px solid transparent;
                border-left: 24px solid #F5F1ED;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
            }
            &::after{
                content: '';
                width: 0;
                height: 0;
                border-top: 28px solid transparent;
                border-bottom: 28px solid transparent;
                border-left: 24px solid #fff;
                position: absolute;
                left: 100%;
                top: 0;
                z-index: 1;
            }
            &.active{
                background-color: $orange;
                color: #fff;
                &::after{
                    border-left: 24px solid $orange;
                }
            }
            &:first-child{
                border-radius: 8px 0 0 8px;
                &::before{
                    content: none;
                }
            }
            &:last-child{
                border-radius: 0 8px 8px 0;
                &::after{
                    content: none;
                }
            }
        }
    }
}