*::placeholder{
    color: #D5CCC4;
}
// <div class="c-formInput"><input type="text" placeholder=""></div>
.c-formInput{
    margin-top: 1.8rem;
    &:first-child{
        margin-top: 0;
    }
    input{
        width: 100%;
        // max-width: 600px;
        height: 4.8rem;
        padding: 1.6rem 2rem;
        background-color: #fff;
        // border: 1px solid #565050;
        border-radius: 8px;
        font-size: 1.4rem;
        letter-spacing: .08em;
    }
    &--half{
        input{
            width: 48.5%;
            max-width: 280px;
        }
    }
    &--full{
        input{
            width: 100%;
            max-width: initial;
        }
    }
    &--beige,
    &.-beige
    {
        input{
            background-color: #F5F1ED;
        }
    }
    &.-calendar{
        position: relative;
        &::after{
            content: '';
            width: 2rem;
            height: 2rem;
            display: block;
            background: no-repeat url(../img/icon_calender.png) center center / contain;
            position: absolute;
            top: 50%;
            right: 8%;
            transform: translateY(-50%);
        }
    }
}

// <div class="c-formSelect">
//     <div class="select">
//         <select name="" id="">
//             <option value="">都道府県選択</option>
//             <option value="北海道">北海道</option>
//             <option value="沖縄県">沖縄県</option>
//         </select>
//     </div>
// </div>
.c-formSelect{
    margin-top: 1rem;
    &:first-child{
        margin-top: 0;
    }
    .select{
        width: 100%;
        max-width: 160px;
        display: inline-block;
        position: relative;
        // &::after{ // arrow
        //     content: '';
        //     display: block;
        //     margin-top: -.36rem;
        //     width: .7rem;
        //     height: .7rem;
        //     border: 1px solid #a9a9a9;
        //     border-width: 0 2px 2px 0;
        //     position: absolute;
        //     top: 50%;
        //     right: 1.2rem;
        //     transform: rotate(45deg);
        //     pointer-events: none;
        // }
        &::after{ // triangle
            content: '';
            display: block;
            margin-top: -.36rem;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-top: 12px solid $brown;
            border-bottom: 0;
            position: absolute;
            top: 50%;
            right: 1.2rem;
            pointer-events: none;
        }
        select{
            width: 100%;
            height: 5rem;
            padding: 0 1.5rem;
            background-color: #fff;
            border-radius: 8px;
            font-size: 1.6rem;
        }
    }
    &--beige,
    &.-beige
    {
        select{
            background-color: $beige;
        }
    }
    &--small,
    &.-small
    {
        select{
            height: 3.2rem;
        }
    }
}

// <div class="c-formCheckbox c-formCheckbox--horizontal">
//     <label for="checkbox1" class="c-formCheckbox__label">
//         <input type="checkbox" name="sample" id="checkbox1">電話で折り返し<span class="checkbox"></span>
//     </label>
//     <label for="checkbox2" class="c-formCheckbox__label">
//         <input type="checkbox" name="sample" id="checkbox2">メールで折り返し<span class="checkbox"></span>
//     </label>
// </div>
.c-formCheckbox{
    &__label{
        // height: 2.8rem;
        padding-left: 3.1rem;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1.4rem;
        line-height: 2.4rem;
        .checkbox{
            width: 2.4rem;
            height: 2.4rem;
            background-color: #fff;
            border: 1px solid #fff;
            border-radius: 4px;
            display: block;
            position: absolute;
            top: .2rem;
            left: 0;
            text-align: center;
            &::after{
                content: '';
                display: none;
                width: 8px;
                height: 12px;
                border-right: 2px solid $orange;
                border-bottom: 2px solid $orange;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-40%,-60%) rotate(45deg);
            }
        }
        input{
            display: none;
            &:checked{
                ~ .checkbox{
                    &::after{
                        display: block;
                    }
                }
            }
        }
        a{
            text-decoration: underline;
        }
    }
    &--horizontal{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    &--horizontal &__label{
        margin-top: 0;
        margin-right: 3.5rem;
        @include ts{
            margin-right: 2rem;
        }
        &:last-of-type{
            margin-left: 0;
        }
    }
    &--beige,
    &.-beige{
        .checkbox{
            background-color: $beige;
            border-color: $beige;
        }
    }
    &--orange,
    &.-orange {
        .checkbox{
            border-color: $orange;
        }
    }
}

// <div class="c-formRadio c-formRadio--horizontal">
//     <label for="radio1" class="c-formRadio__label">
//         <input type="radio" name="sample" id="radio1">電話で折り返し<span class="radio"></span>
//     </label>
//     <label for="radio2" class="c-formRadio__label">
//         <input type="radio" name="sample" id="radio2">メールで折り返し<span class="radio"></span>
//     </label>
// </div>
.c-formRadio{
    &__label{
        margin-top: 1.2rem;
        // height: 1.6rem;
        padding-left: 3.1rem;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1.4rem;
        line-height: 2.8rem;
        &:first-of-type{
            margin-top: 0;
        }
        .radio{
            width: 2rem;
            height: 2rem;
            background-color: #fff;
            // border: 1px solid #EBEBEB;
            border-radius: 50%;
            display: block;
            position: absolute;
            top: .4rem;
            left: 0;
            text-align: center;
            &::after{
                content: '';
                display: none;
                width: 1.4rem;
                height: 1.4rem;
                background-color: $orange;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) rotate(45deg);
            }
            &.-beige {
                background-color: $beige;
            }
        }
        input{
            display: none;
            &:checked{
                ~ .radio{
                    &::after{
                        display: block;
                    }
                }
            }
        }
        a{
            text-decoration: underline;
        }
    }
    &--horizontal{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    &--horizontal &__label{
        margin-top: 0;
        margin-left: 3.5rem;
        &:first-of-type{
            margin-left: 0;
        }
    }
    &--beige,
    &.-beige{
        .radio{
            background-color: $beige;
            border-color: $beige;
        }
    }
}

// <div class="c-formTextarea"><textarea name="" id="" cols="30" rows="10" placeholder="お問い合わせ内容をご記入くださいませ"></textarea></div>
.c-formTextarea{
    width: 100%;
    textarea{
        width: 100%;
        height: 30rem;
        padding: 1.6rem 2rem;
        background-color: #fff;
        // border: 1px solid #565050;
        border-radius: 8px;
        font-size: 1.4rem;
        letter-spacing: .1em;
        line-height: 1.5;
        @include ts{
            padding: 1.4rem 1.6rem;
            font-size: 1.4rem;
        }
    }
    &--beige,
    &.-beige{
        textarea{
            background-color: #F5F1ED;
        }
    }
}

// <span class="c-formRequired">必須</span>
.c-formRequired{
    width: 4rem;
    height: 1.8rem;
    background-color: #fc2161;
    border-radius: 6px;
    display: inline-block;
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.8rem;
    text-align: center;
    font-weight: $regular;
    vertical-align: middle;
}

// <span class="c-formRequired">任意</span>
.c-formOptional{
    width: 8rem;
    height: 2.4rem;
    background-color: transparent;
    display: inline-block;
    color: #fff;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: center;
    font-weight: $regular;
}

.c-formSubmit{
    width: 90%;
    max-width: 37rem;
    height: 8.2rem;
    background-color: #a4a4a4;
    border-radius: 8px;
    display: inline-block;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    line-height: 8.2rem;
    input,button{
        display: block;
        width: 100%;
    }
}