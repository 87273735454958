.c-pager{
    display: flex;
    justify-content: center;
    &__item{
        width: 2.8rem;
        height: 2.8rem;
        a{
            width: 100%;
            height: 100%;
            // background-color: #fff;
            // border: 1px solid $c5d;
            border-radius: 50%;
            display: block;
            font-size: 1.6rem;
            text-align: center;
            line-height: 2.8rem
        }
        &--active{
            @extend .c-pager__item;
            a{
                background-color: $orange;
                // border-color: $orange;
                color: #fff;
            }
        }
        &--prev,
        &--next
        {
            @extend .c-pager__item;
            width: 4rem;
            a{
                // background-color: $c5d;
                // border-color: $c5d;
                position: relative;
                // &::before{
                //     content: '';
                //     width: 2.5rem;
                //     height: .1rem;
                //     background-color: #fff;
                //     display: block;
                //     position: absolute;
                //     top: 50%;
                // }
                // &::after{
                //     content: '';
                //     width: 4px;
                //     height: 4px;
                //     background-color: #fff;
                //     border-radius: 2px;
                //     display: block;
                //     position: absolute;
                //     top: 50%;
                //     margin-top: -2px;
                // }
            }
        }
        &--prev{
            a{
                // &::before{
                //     left: 50%;
                //     margin-left: -1.25rem;
                // }
                // &::after{
                //     left: 50%;
                //     margin-left: -1.5rem;
                // }
            }
        }
        &--next{
            a{
                // &::before{
                //     right: 50%;
                //     margin-right: -1.25rem;
                // }
                // &::after{
                //     right: 50%;
                //     margin-right: -1.5rem;
                // }
            }
        }
    }
}

.wp-pagenavi{
    margin: 1rem 0 0;
    text-align: center;
    .page,
    .current,
    .extend,
    .previouspostslink,
    .nextpostslink,
    .first,
    .last{
        margin: 1rem 2px;
        width: 8rem;
        height: 8rem;
        display: inline-block;
        font-size: 1.8rem;
        line-height: 8rem;
        font-weight: $medium;
        text-align: center;
        @include ts{
            width: 4rem;
            height: 4rem;
            font-size: 1.4rem;
            line-height: 4rem;
        }
    }
    .page{
        background:linear-gradient(90deg, #3083ca, #5c4ea8);
        background-color: transparent;
        color: $orange;
        position: relative;
        z-index: 0;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: .2rem;
            left: .2rem;
            z-index: -1;
            width: calc(8rem - .4rem);
            height: calc(8rem - .4rem);
            background-color: #fff;
            @include ts{
                width: calc(4rem - .2rem);
                height: calc(4rem - .2rem);
                top: .1rem;
                left: .1rem;
            }
        }
    }
    .current{
        background:linear-gradient(90deg, #3083ca, #5c4ea8);
        color: #fff;
    }
    .extend{
        color: $orange;
    }
    .previouspostslink,
    .nextpostslink{
        color: $orange;
        // font-size: 1.5rem;
    }
    .first,
    .last{
        color: $orange;
        // font-size: 2rem;
    }
}