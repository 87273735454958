.l-header,
.l-contentsTop,
.l-contents,
.l-contentsBottom,
.l-footer{
    margin: 0 auto;
    width: 100%;
    // min-width: 1024px;
    @include sp{
        min-width: auto;
    }
}
.l{
    &-header{
        // min-height: 11.8rem;
        // @include tl{
        //     min-height: 8.8rem;
        // }
    }
    &-contentsTop{
        // box-sizing: border-box;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // flex-wrap: wrap;
        // overflow: hidden;
    }
    &-contents{
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
    }
    &-contentsBottom{
        // box-sizing: border-box;
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        // overflow: hidden;
    }
    &-footer{}
}


// 1カラムの時
.l-main{
    width: 100%;
    display: block;
    position: relative;
}

// 左サイドバーの時
.l-sideLeft{
    margin-left: auto;
    margin-right: 48px;
    
    // width: 14.58333%;
    // max-width: 210px;
    width: 221px;
    @media (max-width:1160px){
        margin-right: 4%;
    }
    @include ts{
        width: 100%;
        margin: 0;
    }
    + .l-mainWithColumn{
        display: block;
        margin-left: 0;
        margin-right: auto;
        
        // width: 68.75%;
        width:831px;
        @media (max-width:1160px){
            width: calc(100% - 221px - 4% - 60px);
        }
        @include ts{
            width: 100%;
        }
    }
}

// 右サイドバーの時
.l-mainWithColumn{
    display: block;
    margin-left: auto;
        
    width: calc(100% - 220px - 48px);
    // max-width:730px;
    @include ts{
        margin: 0 auto;
        width: 100%;
    }
    + .l-right{
        margin-right: auto;
        margin-left: 128px;
        
        // width: 14.58333%;
        // max-width: 210px;
        width: 250px;
        @include ps{
            margin-left: auto;
        }
        @include tl{
            width: 100%;
        }
    }
}
.l-drawer{
	display: none;
	position: fixed;
    // top: 8.8rem;
    top: 0;
	left: 0;
    width: 260px;
    // width: 100%;
    // height: calc(100% - 8.8rem);
    height: 100%;
    z-index: 1000;
    background-color: #fff;
    overflow-y: scroll;
    transform: translateX(-300px);
    transition: all .3s;
    &.is_active{
        display: block;
        transform: translateX(0);
        transition: all .3s;
    }
}
.l-drawerClose{
    display: none;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1rem;
    left: 270px;
    z-index: 100000;
    cursor: pointer;
    font-size: 2.4rem;
    @include tl{
        // display: block;
        text-align: center;
        line-height: 5rem;
    }
    &.is_active{
        display: block;
    }
    div{
        width: 2rem;
        height: 2px;
        background-color: #333;
        border-radius: 1px;
        position: absolute;
        top: calc(50% - 1px);
        right: calc(50% - 10px);
        transition: .3s;
        &:first-child{
            transform: translateY(-6px);
        }
        &:nth-child(2){
            transform: translateY(0);
        }
        &:last-child{
            transform: translateY(6px);
        }
    }
    body.home & div{
        background-color: #fff;
    }
    &.is_active{
        body.home & div,
        div{
            background-color: #333;
            &:first-child{
                transform: rotate(45deg);
            }
            &:nth-child(2){
                display: none;
            }
            &:last-child{
                transform: rotate(-45deg);
            }
        }
    }
}

.l-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background: transparent;
    transform: translateX(0);
    transition: all .3s;
    visibility: hidden;
    @include tl{
        display: block;
    }
}
.have_curtain .l-overlay {
    display: none;
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    visibility: visible;
    @include tl{
        display: block;
    }
}