.programAbout {
    background-image: url("../img/home/home_map.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 100%;
    padding: 12.8% 0 50%;
}
.programAll ,.programLocal {
    .p-blockC {
        margin: 0 0 7.7%;
    }
    .program_content {
        .content-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 0 12.8%;
            @include sp {
                margin: 0 0 48px;
            }
            &_item {
                background-color: #fff;
                border-radius: 12px;
                width: 30.5%;
                @include ts {
                    width: 48.5%;
                    margin: 0 0 6.95%;
                    &:last-child {
                        margin: 0;
                    }
                }
                @include sp {
                    width: 100%;
                    margin: 0 0 48px;
                }
                .item-inner {
                    position: relative;
                    padding: 54px 0;
                    .c-title24 {
                        line-height: 1.5;
                        margin: 0 0 10px;
                    }
                    .img {
                        width: 170px;
                        margin: 0 auto 20px;
                    }
                    p {
                        line-height: 1.75;
                        padding: 0 30px;
                    }
                }
                &:first-child {
                    width: 100%;
                    height: auto;
                    margin: 0 0 6.95%;
                    @include sp {
                        margin: 0 0 48px;
                    }
                    .item-inner {
                        .item-detail {
                            padding: 0 32px;
                            ol {
                                display: flex;
                                justify-content: space-between;
                                @include sp {
                                    display: block;
                                }
                                li {
                                    width: 47.5%;
                                    @include sp {
                                        width: 100%;
                                        &:first-child {
                                            margin: 0 0 16%;
                                        }
                                    }
                                    .img {
                                        width: 215px;
                                        margin: 0 auto 8%;

                                    }
                                    h4 {
                                        text-align: center;
                                        color: $orange;
                                        font-size: 20px;
                                        margin: 0 0 2%;
                                        @include sp {
                                            padding: 0 20px;
                                        }
                                    }
                                    p {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .price-table {
        .c-title24 {
            margin: 0 0 24px;
        }
        &_wrap {
            background-color: #fff;
            border-radius: 12px;
            margin: 0 0 32px;
            padding: 32px;
            .table {
                width: 100%;
                border-collapse: unset;
                text-align: center;
                overflow-x: scroll;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
                @include sl {
                    display: block;
                    tbody {
                        width: 840px;
                        display: block;
                    }
                }
                tr {
                    &:nth-child(2n) {
                        background-color: #F5F1ED;
                    }
                }
                &-head {
                    th {
                        text-align: center;
                        position: relative;
                        p {
                            color: $orange;
                            margin: 24px 0 6px;
                        }
                        span {
                            display: block;
                            margin: 0 0 30px;
                        }
                        &:not(:first-child){
                            width: 200px;
                            &::after {
                                content: "";
                                position: absolute;
                                background-color: $orange;
                                width: 90%;
                                height: 16px;
                                border-radius: 4px 4px 0 0;
                                margin: 0 auto;
                                top: 0;
                                left: 50%;
                                transform: translate(-50%,0);
                            }
                        }
                    }
                }
                &-body {
                    td {
                        padding: 16px 0;
                        border-radius: 4px;
                        &:first-child {
                            padding: 16px 0 16px 16px;
                            text-align: left;
                            color: $orange;
                        }
                    }
                }
            }
        }
        .btn_wrap {
            text-align: center;
            margin: 0 0 120px;
        }
    }
}
.programLocal {
    .program_content {
        .content-list {
            &_item {
                .item-inner {
                    .img {
                        width: 200px;
                    }
                }
                &:nth-child(2n) {
                    .item-inner {
                        .c-title24 {
                            margin: 0 0 45px;
                        }
                    }
                }
            }
        }
    }
    .price-table {
        &_wrap {
            .table {
                tr {
                    &:nth-child(2n) {
                        background-color: #fff;
                    }
                    &:nth-child(2n+1) {
                        background-color: #f5f1ed;
                    }
                    &:first-child {
                        background-color: #fff;
                    }
                }
            }
            .note {
                font-size: 12px;
                margin: 16px 0 0;
                a {
                    color: $orange;
                    text-decoration: underline;
                }
            }
        }
    }
}