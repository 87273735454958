.single-container{
    padding: 12rem 0 10rem;
    @include ts{
        padding: 6rem 0 5rem;
    }
}

.single-event {
    .p-article {
        .p-login__form {
            margin: 0 0 6.4rem;
            form {
                dt {
                    margin: 0 0 1.2rem;
                }
                dl {
                    margin: 0 0 2.4rem;
                }
                .c-formSelect {
                    .select {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
.recent-news {
    position: relative;
    &_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 4rem;
    }
    &__head{
        margin-bottom: 2.4rem;
    }
    &__btn{
        position: absolute;
        top: .7rem;
        right: 0;
    }
}

